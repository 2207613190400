/* Sen */
@font-face {
  font-display: swap;
  font-family: 'Sen Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Sen-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Sen Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Sen-Bold.ttf') format('truetype');
}

/* Bandeins */
@font-face {
  font-display: swap;
  font-family: 'Bandeins Strange Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/BandeinsStrange-Bold.woff2') format('woff2');
}
